import { ProductCatalogService } from '@yol-digital/ms-client';
import { Promotion } from 'interfaces';
import { PCProduct } from '../interfaces/pc-product.class';
import { ProductListItem } from '../interfaces/product-list-item.interface';

export const productCatalogMockedResponse: ProductCatalogService.CatalogResponse = {
  availableLockings: [],
  activationFee: 0,
  basePrice: 100,
  productSpecCategory: '',
  productSpecClass: 'POSTPAID',
  displayName: 'test product',
  shortName: 'test-product',
  name: 'test-product',
  code: 'TEST_PRODUCT',
  id: 'rateplan_1234',
  productSpecFamily: 'mobile',
  source: 'zira',
  productSpecBrand: 'yallo',
  productSpecCode: 'TEST',
  defaultPromotion: null,
};

export const products: ProductListItem[] = [
  {
    product: new PCProduct(),
    promotion: new Promotion(),
    featured: true,
    local: true,
    global: true,
    prepaid: true,
    all: true,
  },
  {
    product: new PCProduct(),
    promotion: new Promotion(),
    featured: true,
    local: true,
    global: false,
    prepaid: false,
    all: false,
  },
];
