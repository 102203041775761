import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Content, FilledContentRelationshipField, FilledLinkToWebField, LinkField } from '@prismicio/client';
import { ProductCatalogService, RequestParams } from '@yol-digital/ms-client';
import { EMPTY, lastValueFrom, Observable } from 'rxjs';
import { catchError, map, shareReplay, timeout } from 'rxjs/operators';
import { Promotion } from 'interfaces';
import { LanguageService } from 'language';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProductBoxDocument, PromotionService } from 'prismic';
import { ToastService } from 'toast';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslateService } from 'translate';
import { CachedDataLoaderService, ENVIRONMENT_URLS_CONFIG_TOKEN, ObservableCachedDataLoaderService } from 'utils';
import { PCProduct, ProductCombined } from './interfaces/pc-product.class';
import { ProductListItem } from './interfaces/product-list-item.interface';
import Api = ProductCatalogService.Api;
import CatalogResponse = ProductCatalogService.CatalogResponse;
import PromotionResponse = ProductCatalogService.PromotionResponse;

export abstract class ProductService {
  protected api: Api;
  protected queryParams: Params;
  protected languageService = inject(LanguageService);
  protected dataLoader = inject(CachedDataLoaderService);
  protected observableDataLoader = inject(ObservableCachedDataLoaderService);
  protected http = inject(HttpClient);
  protected toasts = inject(ToastService);
  protected translateService = inject(TranslateService);
  protected route = inject(ActivatedRoute);
  protected promotionService = inject(PromotionService);
  protected config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  protected platformId = inject(PLATFORM_ID);
  private router = inject(Router);

  constructor() {
    this.api = new Api(this.config.newMicroServiceEndpoint, this.http);

    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }

  protected getRatePlans() {
    let productCatalogCall$ = this.getCatalog();
    if (isPlatformServer(this.platformId)) {
      productCatalogCall$ = productCatalogCall$.pipe(timeout(5000));
    }
    return productCatalogCall$.pipe(
      catchError(err => {
        console.error('Error while retrieving products', err);
        this.toasts.add(this.translateService.getTranslation(['server_error']), false);
        return EMPTY;
      }),
      map(({ plans }: { plans: CatalogResponse[] }) => plans),
      shareReplay(1)
    );
  }

  protected abstract getCatalog(
    query?: { code?: string },
    params?: RequestParams
  ): Observable<{ plans: CatalogResponse[] } | { error: string }>;

  public abstract getPromotionByCode(code: string): Observable<{ promotions: PromotionResponse[] } | { error: string }>;

  public async getPCProducts(): Promise<CatalogResponse[]> {
    return this.dataLoader.get('PC_products', () => lastValueFrom(this.getRatePlans()).catch(() => null));
  }

  public abstract getPCProducts$(): Observable<CatalogResponse[]>;

  public abstract getPCProductByCode(code: string): Observable<CatalogResponse>;

  public sortProductsByDiscount(a: ProductListItem, b: ProductListItem): number {
    const a_price = a.promotion ? a.product.discountedPrice(a.promotion?.discountAmount) : a.product.monthly_cost;
    const b_price = b.promotion ? b.product.discountedPrice(b.promotion?.discountAmount) : b.product.monthly_cost;
    if (Number(a_price) < Number(b_price)) {
      return -1;
    }
    if (Number(a_price) > Number(b_price)) {
      return 1;
    }
    return 0;
  }

  protected buildCheckoutQueryParams(
    product: PCProduct,
    promotion?: Promotion,
    lineCheckId?: string,
    isLegacy = false
  ): { [key: string]: string } {
    const queryParamsObj: { [key: string]: string } = {};

    const effectiveLockingPeriod = Math.min(
      ...(promotion?.lockinLengths?.length ? promotion.lockinLengths : product.lockin_length || [])
    );

    if (!isNaN(effectiveLockingPeriod)) {
      const lockingPeriodKey = isLegacy ? 'll' : 'lockingPeriod';
      queryParamsObj[lockingPeriodKey] = effectiveLockingPeriod.toString();
    }

    if (promotion?.code) {
      const promotionKey = isLegacy ? 'promotions' : 'promotion';
      queryParamsObj[promotionKey] = promotion.code;
    }

    if (lineCheckId) {
      queryParamsObj.lineCheckId = lineCheckId;
    }

    const { utm_medium, utm_source, utm_campaign } = this.queryParams;
    if (utm_medium && utm_source && utm_campaign) {
      queryParamsObj.utm_medium = utm_medium;
      queryParamsObj.utm_source = utm_source;
      queryParamsObj.utm_campaign = utm_campaign;
    }

    return queryParamsObj;
  }

  public abstract buildCheckoutPath(
    product: PCProduct,
    promotion?: Promotion,
    lineCheckId?: string
  ): { link: LinkField; queryParams?: object };

  public isLinkToCheckout(linkField: LinkField) {
    return (
      (linkField.link_type === 'Document' && (linkField as FilledContentRelationshipField).slug?.includes('eshop')) ||
      (linkField.link_type === 'Web' && (linkField as FilledLinkToWebField).url.search(this.config.checkoutUrl) !== -1)
    );
  }

  public getPCPromotionByCode(code: string): Observable<{ promotions: PromotionResponse[] } | { error: string }> {
    return this.observableDataLoader.get('PC_promo_' + code, () => this.getPromotionByCode(code));
  }

  public async convertProductBox(
    prismicDocument: ProductBoxDocument,
    promotionFromPrismic?: FilledContentRelationshipField
  ): Promise<ProductListItem> {
    const productsFromPC = await this.getPCProducts();
    if (!productsFromPC) return null;
    const productFromMs = productsFromPC.find(
      (product: ProductCatalogService.CatalogResponse) => product.code === prismicDocument?.data?.product_code
    );

    if (!productFromMs) return null;
    const product = PCProduct.fromMS(productFromMs, prismicDocument.data as Content.ProductBoxDocumentData);
    const promotion = await this.getCorrectPromotion(promotionFromPrismic, productFromMs);

    return {
      product,
      promotion,
    };
  }

  public async getCorrectPromotion(
    promotionFromPrismic?: FilledContentRelationshipField,
    productFromMs?: ProductCatalogService.CatalogResponse,
    promotionCodeFromOfferCtaLink?: string
  ) {
    // If theres promotion coming from landing page, overwrite the promotion from CTA link (if any - only offer) and the default from PC
    const promotionIdFromPrismic = promotionFromPrismic?.id;
    if (promotionIdFromPrismic) {
      const _promotion = await this.promotionService.getPromotionById(promotionFromPrismic.id);
      if (_promotion) return _promotion;
      else console.warn('Promotion linked to product is not valid', promotionFromPrismic, productFromMs.displayName);
    } else if (promotionCodeFromOfferCtaLink) {
      // An offer can have a cta slice with a button link text that can contain promotion code
      const _promotion = await this.promotionService.getPromotionByCode(promotionCodeFromOfferCtaLink);
      if (_promotion) return _promotion;
      else console.warn('Promotion linked to offer is not valid', productFromMs?.displayName);
    } else {
      //default promo from PC
      if (productFromMs && productFromMs.defaultPromotion) {
        const promoFromPrismic = await this.promotionService.getPromotionByCode(productFromMs.defaultPromotion.code);
        const promotion = Promotion.fromMS(productFromMs.defaultPromotion, promoFromPrismic);
        return promotion;
      }
    }
  }

  public isLineCheckHidden() {
    return false;
  }

  public productDetailsLink(product: ProductCombined) {
    if (!product.product_details_link || product.product_details_link.link_type === 'Any') {
      return {
        link_type: 'Document',
        slug: `mobile-products/${product.url}`,
      };
    }
    return product.product_details_link;
  }

  public hideProductsIfNotInPage(
    products: ProductCatalogService.CatalogResponse[],
    productCodesToHide: string[],
    page: string
  ) {
    if (!this.router.url.includes(page)) {
      return products.filter(({ code }) => !productCodesToHide.includes(code));
    }
    return products;
  }
}
