export interface ProductsPaths {
  yallo: {
    [key: string]: string | undefined;
  };
  lebara: {
    [key: string]: string | undefined;
  };
}
export const productsPaths: ProductsPaths = {
  yallo: {
    'home max cable': '/cable',
    'home max fiber': '/fiber',
    'home cable m': '/cable-m',
    'home cable s': '/cable-s',
    'home cable s +tv': '/cable-s-plus-tv',
    'home cable m +tv': '/cable-m-plus-tv',
    'home max fiber +tv': '/fiber-plus-tv',
    'home max cable +tv': '/cable-plus-tv',
    'home 5g': '/home-5g',
    'home 4g': '/home-internet-4g',
    black: '/mobile-products/yallo_black',
    fat: '/mobile-products/yallo_fat',
    'fat plus': '/mobile-products/yallo_fat_plus',
    go: '/internet-go',
    'home 5g +tv': '/home-5g-plus-tv',
    regular: '/mobile-products/yallo_regular',
    'regular plus': '/mobile-products/yallo_regular_plus',
    'slim plus': '/mobile-products/yallo_slim_plus',
    'super fat': '/mobile-products/yallo_super_fat',
    'super fat plus': '/mobile-products/yallo_super_fat_plus',
    'super fat xxl': '/mobile-products/yallo_super_fat_xxl',
    'swiss flat': '/mobile-products/yallo_swiss_flat',
    'swiss plus': '/mobile-products/yallo_swiss_plus',
    'go s': '/internet-go',
    'go max': '/internet-go',
    'swiss flat 5g': '/mobile-products/yallo_swiss_flat_5g',
    'fat plus 5g': '/mobile-products/yallo_fat_plus_5g',
    'yallo tv': '/tv',
    prepaid: '/yallo_prepaid',
  },
  lebara: {
    data: '/lebara_data',
    europe: '/lebara_europe',
    'europe l': '/lebara_europe_l',
    'europe xl': '/lebara_europe_xl',
    swiss: '/lebara_swiss',
    'swiss flat': '/lebara_swiss_flat',
    xxl: '/lebara_xxl',
    next: '/lebara_next',
    'swiss l': '/lebara_swiss_l',
    'lebara prepaid plus': '/lebara_plus',
  },
};
